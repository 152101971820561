import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColors } from "./index";

const parseData = (data) => {
  if (data && (data.length || Object.keys(data)?.length))
    return {
      bar: data.bar ? Object.values(data.bar) : Array.isArray(data) ? data : [],
      total: data.total ? data.total : [],
    };
  return {
    bar: [],
    total: [],
  };
};

const ColumnsWidget = ({ data, heading }) => {
  const [formattedData, setFormattedData] = useState({
    bar: [],
    total: [],
  });

  useEffect(() => {
    setFormattedData(parseData(data));
  }, [data]);

  return (
    <div className="col col-12 row mx-0 mt-3 px-2">
    <div className= "row mx-0 justify-content-between col col-12 mt-2 px-0 ">
    <div className="dashboard__card col col-12 w-100 flex-basis-100">
    {heading ? <h4 className="mb-1 mt-2">{heading}</h4> : ""}
          <div className="d-flex flex-col mx-3 my-2" style={{ gap: "3rem" }}>
            {formattedData.total.map((total) => (
              <div>
                <p className="mb-0 font-weight-bold">
                  {total.formated_value ? total.formated_value : total.value}
                </p>
                <p className="mb-0" style={{ fontSize: "0.75rem" }}>
                  {total.name}
                </p>
              </div>
            ))}
          </div>
          <Chart
            width="100%"
            height="200px"
            type="bar"
            series={[
              {
                name: heading,
                data: formattedData.bar.map((data) => parseFloat(data.value)),
              },
            ]}
            options={{
              chart: {
                type: "bar",
              },
              plotOptions: {
                bar: {
                  vertical: true,
                  borderRadius: 25,
                },
              },
              colors: chartThemeColors,
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) =>
                  `<div class='bar-widget__tooltip'>
              <p class='bar-widget__tooltip-primary'>${
                formattedData.bar[dataPointIndex].value_formatted
                  ? formattedData.bar[dataPointIndex].value_formatted
                  : formattedData.bar[dataPointIndex].value
              }</p>
                ${
                  formattedData.bar[dataPointIndex].value2_formatted
                    ? "<p class='bar-widget__tooltip-secondary'>" +
                      formattedData.bar[dataPointIndex].value2_formatted +
                      "</p>"
                    : ""
                }
              </div>`,
              },
              xaxis: {
                categories: formattedData.bar.map((data) => data.name),
                labels: { show: true },
              },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return value.toFixed(2);
                  },
                  show:false,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnsWidget;
