import CardsWidget from './CardsWidget'
import ColumnsWidget from './ColumnsWidget'
import DonutWidget from './DonutWidget'

/*
  web_type: cards_widget
  details: [
    {
      name: string
      value: number
      formatted_value: string
      icon: string
    },
    ...
  ]
  web_type: bars_widget
  details: {
    bar: [
      {
        name: string,
        value: number,          (eg total_amount)
        value_formatted: string,
        value2: number,         (eg total_orders)
        value2_formatted: string,
      }
      ... 11 others
    ],
    total: [
      {
        name: string,
        value: number,
        value_formatted: string,
      }
    ]
  }
  web_type: donut_widget
  details: {
    chart: [
      {
        name: string,
        value: number,          (eg total_amount)
        value_formatted: string,
        value2: number,         (eg total_orders)
        value2_formatted: string,
      }
      ... 11 others
    ],
    total: [
      {
        name: string,
        value: number,
        value_formatted: string,
      }
    ]
  }

  web_type: donut_widget
  details: {
    donut: {
      {
        name: string,
        value: number,            (eg total_amount)
        value_formatted: string,
        value2: number,           (eg total_orders)
        value2_formatted: string,
      }
      ...
    },
    total: [
      {
        name: string,             (eg Total Orders)
        value: number,
        value_formatted: string,
      }
    ]
  }
*/

export const chartThemeColors = [
  '#0A8D6E',
  '#27CCAA',
  '#b2df8a',
  '#80B97D',
  '#72A247',
  '#0d98ba',
  '#36C453',
  '#088080',
  '#0CBAA6',
  '#50808E',
  '#A3D2D5'
]

export {
  CardsWidget,
  ColumnsWidget,
  DonutWidget,
}