import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  checkPermission,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
  vatPercent
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { each } from "jquery";
import SettledAddReason from "./SettledAddReason";

const Settled = () => {
  const { authUserInfo } = useContext(UserContext);
  //getting context values here
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);

  const {
    //submitted orders
    settledOrders,
    setSettledOrders,
    setPaginatedSettledOrders,
    settledOrdersForSearch,

    //payment-type
    paymentTypeForSearch,
    orderTypeList,
    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();
  //print bills
  const componentRef = useRef();

  // States hook here
  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  const [orderForAction, setOrderForAction] = useState({});

  const [reason, setReason] = useState("");
  const [taxType, setTaxType] = useState("");

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
    srb_pos_invoice_no: null,
    srb_pos_invoice_no_for_print: null,
  });

  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });

  const [showAddReasonModal, setShowAddReasonModal] = useState(false);
  const [activebtn , setActivebtn] = useState("");

  const [passcodeApplied, setPasscodeApplied] = useState(false);
  const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState([]);

  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  useEffect(()=>{
    const filteredDeliveryTypes = orderTypeList.data.filter(
      (order) => order.status === 'On'
    );
    if (filteredDeliveryTypes.length > 0) {
      setAvailableDeliveryTypes(filteredDeliveryTypes);
    }
  },[orderTypeList]);

  useEffect(() => {
    if (!authUserInfo) return
    setPasscodeApplied(
      (authUserInfo.details.user_type === "staff" && authUserInfo.details.user_passcode) ? true : false
    );
  }, [authUserInfo]);

  useEffect(() => {
    if (!generalSettings || !generalSettings.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);


  //useEffect == componentDidMount
  useEffect(() => {}, []);

   // show property price
   const showPropertyPrice=(qty,propQty,price)=>{
    let propPrice = 0;
    if (taxType === "inclusive") {
      propPrice = qty*propQty*price;
      let finalPrice = propPrice / (1 + checkOrderDetails.item.theBranch.branch_tax / 100);
      return formatPrice(finalPrice);
    }else{
      propPrice = qty*propQty*price;
      return formatPrice(propPrice);
    }
  }
  //show price of each item in print
  const showPriceOfEachOrderItemPrint = (thisItem) => {
    let price = 0;
    let tempPropertyPrice = 0;
    if (thisItem.properties !== null) {
      let propertyItems = JSON.parse(thisItem.properties);
      propertyItems.forEach((propertyItem, thisIndex) => {
        let temp =
          propertyItem.quantity *
          propertyItem.price_per_qty *
          thisItem.quantity;
        tempPropertyPrice = tempPropertyPrice + temp;
      });
    }
    price = thisItem.price - tempPropertyPrice;
    if (taxType === "inclusive") {
      let finalPrice = price / (1 + checkOrderDetails.item.theBranch.branch_tax / 100);
      return formatPrice(finalPrice);
    }else{
      return formatPrice(price);
    }
  };

  //search submitted orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if(settledOrders){
      if (searchInput.length === 0 || searchInput === "all") {
        setSearchedOrder({ ...searchedOrder, searched: false });
      } else {
        let searchedList = settledOrdersForSearch && settledOrdersForSearch?.filter((item) => {
          //token
          let lowerCaseItemToken = item.token && JSON.stringify(item.token?.id)?.toLowerCase();
          
          //customer
          let lowerCaseItemCustomer = item.customer_name && item.customer_name.toLowerCase();
          
          //table
          let lowerCaseItemTable = item.table_name && item.table_name.toLowerCase();

          //branch
          let lowerCaseItemBranch = item.branch_name && item.branch_name.toLowerCase();
            //order type
          let lowerCaseItemOrderType =
            item.order_type_name && item.order_type_name?.toLowerCase();
          return (
            (lowerCaseItemToken && lowerCaseItemToken.includes(searchInput)) ||
            (lowerCaseItemCustomer && lowerCaseItemCustomer.includes(searchInput)) ||
            (lowerCaseItemTable && lowerCaseItemTable.includes(searchInput)) ||
            (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))|| 
            (lowerCaseItemOrderType && lowerCaseItemOrderType.includes(searchInput))
          );
        });
        setSearchedOrder({
          ...searchedOrder,
          list: searchedList,
          searched: true,
        });
      }
    }
  };

  //print here
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => {
    //   this.handlePrintAfter();
    // },
  });

  //ready here
  const handleReadyOrder = (id) => {
    let tempSettledOrders = settledOrders;
    let tempSearchedItems = searchedOrder.list;
    const url = BASE_URL + "/settings/settle-order-ready/" + id;
    let theItems = settledOrders.data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_ready: 1,
          orderedItems: item.orderedItems.map((eachItem) => {
            return {
              ...eachItem,
              is_cooking: 1,
              is_ready: 1,
            };
          }),
        };
      } else {
        return item;
      }
    });
    if (searchedOrder.list !== null && searchedOrder.list.length > 0) {
      let searchedItems = searchedOrder.list.map((searchedItem) => {
        if (searchedItem.id === id) {
          return {
            ...searchedItem,
            is_ready: 1,
            orderedItems: searchedItem.orderedItems.map((eachorderItem) => {
              return {
                ...eachorderItem,
                is_cooking: 1,
                is_ready: 1,
              };
            }),
          };
        } else {
          return searchedItem;
        }
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedItems,
      });
    }
    setSettledOrders({ ...settledOrders, data: theItems });
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("resresres", res);
      })
      .catch((error) => {
        setSettledOrders(tempSettledOrders);
        setSearchedOrder({
          ...searchedOrder,
          list: tempSearchedItems,
        });
      });
  };

  //srb invoice
  const handleSrbInvoice = () => {
  const url = BASE_URL + "/settings/sync-srb";
  let formData = {};
    axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success("Srb ingtegration has been posted",toastObj);
      }).catch((err)=>{
        toast.error("Please try again",toastObj)
      });
  }

  //delete confirmation modal of waiter
  const handleSrbConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h2>{_t(t("Are you sure?"))}</h2>
            <p className="text-center">{_t(t("You want to integrate the orders?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleSrbInvoice();
                  onClose();
                }}
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handlePrint = (item, printType) => {
    console.log("item=====", item, "checkOrderDetails", checkOrderDetails);
    if (printType === "re_print") {
      // const url = BASE_URL + "/settings/settle-order-reprint/" + item.id;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${getCookie()}` },
      //   })
      //   .then((res) => {
      //     console.log("resresres", res);
      //     setCheckOrderDetails({
      //       ...checkOrderDetails,
      //       item: item,
      //       srb_pos_invoice_no: res.data,
      //       srb_pos_invoice_no_for_print: res.data,
      //     });
      //     setTimeout(() => {
      //       handleOnlyPrint();
      //     }, 1000);
      //   });
    } else {
      setCheckOrderDetails({
        ...checkOrderDetails,
        item: item,
        settle: false,
        srb_pos_invoice_no: null,
        srb_pos_invoice_no_for_print:
          item.srb_pos_invoice_no !== ""
            ? item.srb_pos_invoice_no
            : item.fbr_pos_invoice_no,
      });
      setTimeout(() => {
        handleOnlyPrint();
      }, 1000);
    }
  };

  const handleRefund = () => {
    let id = orderForAction.orderedItems[0].order_group_id;

    let theItems = settledOrders.data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_refund: 1,
          orderedItems: item.orderedItems.map((eachItem) => {
            return {
              ...eachItem,
              is_cooking: 1,
              is_refund: 1,
            };
          }),
        };
      } else {
        return item;
      }
    });
    if (searchedOrder.list !== null && searchedOrder.list.length > 0) {
      let searchedItems = searchedOrder.list.map((searchedItem) => {
        if (searchedItem.id === id) {
          return {
            ...searchedItem,
            is_refund: 1,
            orderedItems: searchedItem.orderedItems.map((eachorderItem) => {
              return {
                ...eachorderItem,
                is_cooking: 1,
                is_refund: 1,
              };
            }),
          };
        } else {
          return searchedItem;
        }
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedItems,
      });
    }
    setSettledOrders({ ...settledOrders, data: theItems });

    console.log("itemitem", orderForAction, "reason", reason);
    const url = BASE_URL + "/settings/settle-order-refund";
    const formData = new FormData();
    formData.append(
      "order_group_id",
      orderForAction.orderedItems[0].order_group_id
    );
    formData.append("reason", reason);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("resresres", res);
        setOrderForAction({});
        setReason("");
      });
  };
  
let printText = "";
let taxTaken = "";
let taxImage = "";
const qrPrint = (taxTaken,printText,InvoiceNum,taxImage) =>{
  return(
    <div className="mb-1">
      <p className="print-text fk-print-text text-capitalize text-center">
        {taxTaken} Invoice Number# {InvoiceNum}
      </p>
      <div className="mb-1">
        <div className="float-left">
          <img
            src={taxImage}
            height="80"
            alt={`${taxTaken} Invoice`}
          />
        </div>
        <div className="float-right">
          {InvoiceNum && (
            <QRCode value={InvoiceNum} size="80" />
          )}
        </div>
        <div style={{ clear: "both" }}></div>
        </div>
        <p className="print-text fk-print-text text-center">
          {printText} <br></br> and win exciting prizes in
          draw.
        </p>
    </div>
  )
}

  const itemListContent = (item,index) => {
   return(
    <>
    <tr
    className="align-middle"
    key={index}
  >
    <th
      scope="row"
      className="table-text text-capitalize align-middle text-center"
    >
      {index +
        1 +
        (settledOrders.current_page -
          1) *
          settledOrders.per_page}
    </th>

    <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
      #{item.token?.id}
    </td>

    
    <td className="table-text text-capitalize align-middle text-center table_child_token">
    <Moment format="LT">
    {item.token?.time}
    </Moment>
    </td>
    <td className="table-text text-capitalize align-middle text-center table_child_ordertype">
      {`${item.order_type_name} - ${item?.party_name}`}
    </td>
    <td className="table-text align-middle text-center table_child_ordertype">
      {item.customer_name}
    </td>

    <td className="table-text align-middle text-center">
      {item.table_name}
    </td>

    <td className="table-text align-middle text-center table_child_branch">
      {item.branch_name || "-"}
    </td>
    <td class="table-text text-capitalize align-middle text-center">
      {parseInt(item.is_cancelled) ===
      0 ? (
        [
          parseInt(item.is_ready) ===
          0 && parseInt(
            item.is_refund
          ) == 0 && parseInt(item.is_foc) === 0 ? (
            <span
              class="btn btn-transparent btn-secondary xsm-text text-capitalize"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("processing"))}
            </span>
          ) : parseInt(
              item.is_refund
            ) == 0 && parseInt(item.is_foc) == 0 ? (
            <span
              class="btn btn-transparent btn-success xsm-text text-capitalize px-4"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Ready"))}
            </span>
          ) : 
          parseInt(item.is_foc) === 1 ?
            (
              <span
              class="btn btn-transparent btn-secondary xsm-text text-capitalize px-4"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Free of cost"))}
            </span>
            )
            :
          (
            <span
              class="btn btn-transparent btn-danger xsm-text text-capitalize px-4"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                });
                setReturnMoneyUsd(0);
                setPaidMoney(0);
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Refund"))}
            </span>
          ),
        ]
      ) : (
        <span
          class="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
          onClick={() => {
            setCheckOrderDetails({
              ...checkOrderDetails,
              item: item,
              settle: false,
            });
            setReturnMoneyUsd(0);
            setPaidMoney(0);
          }}
          data-toggle="modal"
          data-target="#orderDetails"
        >
          {_t(t("Cancelled"))}
        </span>
      )}
    </td>
    <td className="table-text align-middle text-center">
      {/* {parseInt(item.is_ready) === 0 ? ( */}
      {authUserInfo.permissions !==
        null && checkPermission(authUserInfo.permissions, "Settle Order Print") && (<button
        className="btn btn-secondary btn-sm"
        onClick={() => {
          handlePrint(
            item,
            "just_print"
          );
        }}
      >
        <i className="fa fa-print"></i>
      </button>)}
      {/* ) : (
        ""
      )} */}

      { !searchedOrder.searched && parseInt(item.is_ready) ===
        0 && parseInt(
          item.is_refund
        ) == 0 && parseInt(item.is_foc) === 0 &&(
        <button
          className="btn btn-success btn-sm ml-2"
          title="Served"
          onClick={() => {
            handleReadyOrder(item.id);
          }}
        >
          <i className="fa fa-check text-"></i>
        </button>
      )}
      {/*authUserInfo.permissions !==
        null &&
      checkPermission(
        authUserInfo.permissions,
        "Reprint"
      ) ? (
        parseInt(item.is_ready) ===
          1 && (
          <button
          data-toggle="tooltip"
            className="re-print-btn ml-2"
            title="Settle"
            onClick={() => {
              handlePrint(
                item,
                "re_print"
              );
            }}
          >
            SETTLE
          </button>
        )
      ) : (
        <></>
      )*/}
      {authUserInfo.permissions !==
        null &&
      checkPermission(
        authUserInfo.permissions,
        "Order refund"
      ) && parseInt(item.is_foc) === 0 ? (
        <button
          className="btn btn-danger btn-sm ml-2"
          data-toggle="modal"
          title="Order refund"
          data-target="#addReson"
          onClick={() => {
            setOrderForAction(item);
            setShowAddReasonModal(true);
          }}
        >
          <i
            style={{ fontSize: "1rem" }}
            className="fa fa-money"
          ></i>
        </button>
      ) : (
        ""
      )}
    </td>
    <td className="table-text text-capitalize align-middle text-center">
    {item?.ref_no ? item.ref_no : "-"}
    </td>
    <td className="table-text text-capitalize align-middle text-center">
        {item.user_name}
    </td>
  </tr>
    </>
   )
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Settled orders"))}</title>
      </Helmet>

      {/* Refund Modal */}
      {showAddReasonModal ?
        <SettledAddReason
          passcodeApplied={passcodeApplied}
          correctPasscode={authUserInfo.details.user_passcode}
          reason={reason}
          setReason={setReason}
          onSuccess={handleRefund}
          setShow={setShowAddReasonModal}
        />
        : null
      }

      {/* Print bill */}
      <div className="d-none">
        <div ref={componentRef}>
          {checkOrderDetails && checkOrderDetails.item && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                  {
                    parseInt(getSystemSettings(generalSettings, "print_logo")) === 1 && (
                      <div className="mx-auto" style={{ width: "120px" }}>
                        <img
                          src={`${getSystemSettings(
                            generalSettings,
                            "type_logo"
                          )}`}
                          style={{ width: "100%"}}
                          //height={100}
                        />
                      </div>
                    ) 
                  }
                  {
                    parseInt(getSystemSettings(generalSettings, "print_siteName")) === 1 && (
                      <span className="mt-1 d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                        {getSystemSettings(generalSettings, "siteName")}
                      </span>
                    ) 
                   }  
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchName")) === 1 && (
                      <span className="mt-1 d-block fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                        {checkOrderDetails.item.branch_name}
                      </span>
                    ) 
                   }
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchAddress")) === 1 && (
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {checkOrderDetails.item.theBranch !== null &&
                      checkOrderDetails.item.theBranch.address
                        ? checkOrderDetails.item.theBranch.address
                        : ""}
                      </p>
                    )
                   }
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchPhone")) === 1 &&(
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {_t(t("call"))}:{" "}
                      {checkOrderDetails.item.theBranch !== null &&
                      checkOrderDetails.item.theBranch.phn_no
                        ? checkOrderDetails.item.theBranch.phn_no
                        : ""}
                     </p>
                    )
                  }
                  {
                    parseInt(getSystemSettings(generalSettings, "print_heading")) === 1 && (
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                       {getSystemSettings(generalSettings, "type_print_heading")}
                      </p>
                    )
                  }
                
                    <span className="d-block fk-print-text text-uppercase text-center lg-text myBorderTopCustomer pt-1">
                    {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "} {checkOrderDetails.item.token?.id}
                    </span>

                    {/* <p className="mb-0 fk-print-text text-capitalize lg-text">
                      {checkOrderDetails.item.dept_tag_name}
                    </p>
                    <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                      {checkOrderDetails.dept_tag_name}
                    </p> */}
                   
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "both" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name +
                          "-" +
                          checkOrderDetails.item?.party_name}
                        </p>
                      )
                    }
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "order_type" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name}
                        </p>
                      )
                    }
                    {
                      getSystemSettings(generalSettings, "print_orderParty") === "party" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {
                          checkOrderDetails.item?.party_name}
                        </p>
                      )
                    }

                    <p className="mb-0 mt-0 print-text fk-print-text text-capitalize text-center">
                      {_t(t("Customer Copy"))}
                    </p>

                    <div className="myBorder mb-2"></div>
                  
                      {
                        checkOrderDetails.item.customer_name && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Customer Name"))}:{" "}
                            {checkOrderDetails.item.customer_name}
                          </p>
                        )
                      }
                      {
                        checkOrderDetails.item.delivery_phn_no && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Phone #"))}:{" "}
                            {checkOrderDetails.item.delivery_phn_no}
                          </p>
                        )
                      }
                      {
                        checkOrderDetails.item.delivery_address && (
                          <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Address "))}:{" "}
                            {checkOrderDetails.item.delivery_address}
                          </p>
                        ) 
                      }
                        <p className="mb-0 print-text fk-print-text text-capitalize">
                          {"ref no :" +
                            (checkOrderDetails.item?.ref_no ? checkOrderDetails.item.ref_no : "")}
                        </p>
                   

                    {checkOrderDetails.item.theBranch.sbr_integration === 0 &&
                    (checkOrderDetails.item.theBranch.fbr_integration === 0 && checkOrderDetails.item.theBranch.pbr_integration === 0) ? (
                      getSystemSettings(
                        generalSettings,
                        "pre_settled_billed_heading"
                      ) ? (
                        <>
                          <p className="mb-0 md-text fk-print-text text-capitalize">
                            SETTLE
                            {/* {getSystemSettings(
                              generalSettings,
                              "pre_settled_billed_heading"
                            )} */}
                          </p>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <>
                        <p className="mb-0 md-text fk-print-text text-capitalize">
                          SETTLE
                        </p>
                      </>
                    )}
                    <p className="mb-0 print-text fk-print-text text-capitalize">
                      {_t(t("date"))}:{" "}
                      <Moment format="LL">
                        {checkOrderDetails.item.created_at}
                      </Moment>
                      {", "}
                      <Moment format="LT">
                        {checkOrderDetails.item.token?.time}
                      </Moment>
                    </p>
                    {
                      checkOrderDetails.item.order_type_name === "Dine-In" && (
                        <p className="mb-0 print-text fk-print-text text-capitalize">
                          {_t(t("guests"))}:{" "}
                          {checkOrderDetails.item.total_guest}
                        </p>
                      )
                    }
                    {checkOrderDetails.item.waiter_name !== "-" && (
                      <p className="mb-0 print-text fk-print-text text-capitalize">
                        {_t(t("waiter name"))}:{" "}
                        {checkOrderDetails.item.waiter_name}
                      </p>
                    )}
                    {parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && (
                      checkOrderDetails.item.is_refund === 1 ? (
                      <>
                        <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                          Refund -{" "}
                          {
                            checkOrderDetails.item.bill_distribution[0]
                              .payment_type
                          }
                        </p>
                        <p className="mb-0 print-text fk-print-text text-capitalize">
                          {_t(t("Reason"))}:{" "}
                          {checkOrderDetails.item.refund_reason}
                        </p>
                      </>
                    ) :
                    checkOrderDetails.item.is_foc === 1 ?
                    (
                      <>
                        <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                          Free of Cost
                        </p>
                      </>
                    ):
                    (
                      <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                        PAID -{" "}
                        {
                          checkOrderDetails.item?.bill_distribution[0]
                            ?.payment_type
                        }
                      </p>
                    ))}

                    <table className="table mb-0 table-borderless akash-table-for-print-padding">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="fk-print-text print-text text-capitalize"
                          >
                            {_t(t("qty"))} {_t(t("item"))}
                          </th>
                          <th
                            scope="col"
                            className="fk-print-text print-text text-capitalize text-right"
                          >
                            {_t(t("T"))}.{_t(t("price"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.orderedItems.map(
                            (thisItem, indexThisItem) => {
                              return (
                                <tr>
                                  <td className="fk-print-text print-text text-capitalize">
                                    <div className="d-flex flex-wrap">
                                      <span className="d-inline-block print-text">
                                        -{thisItem.quantity}{" "}
                                        {thisItem.food_item}
                                        {thisItem.variation !== null &&
                                          "(" + thisItem.variation + ")"}
                                      </span>
                                    </div>

                                    {/* properties */}
                                    {thisItem.properties !== null && (
                                      <div className="d-block">
                                        {JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            return (
                                              <div>
                                                <span className="text-capitalize mt-1 ml-2 print-text d-inline-block mr-1">
                                                  -{thisItem.quantity}
                                                  {propertyItem.quantity > 1
                                                    ? "*" + propertyItem.quantity
                                                    : ""}{" "}
                                                  {propertyItem.property}
                                                </span>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  </td>
                                  <td className="fk-print-text print-text text-capitalize text-right">
                                    <div className="d-block print-text">
                                      {showPriceOfEachOrderItemPrint(thisItem)}
                                    </div>

                                    {/* properties */}
                                    {thisItem.properties !== null && (
                                      <div className="d-block">
                                        {JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            
                                            return (
                                              <div
                                                className="d-block text-capitalize mt-1 print-text"
                                              >
                                                <span>
                                                  {showPropertyPrice(
                                                    thisItem.quantity,
                                                      propertyItem.quantity,
                                                      propertyItem.price_per_qty
                                                  )}
                                                </span>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>

                    <div className="myBorder mb-1"></div>
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            <span className="d-block">{_t(t("total"))}</span>
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(checkOrderDetails.item.order_bill)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {parseFloat(checkOrderDetails.item.vat) > 0 && (
                      <table className="table mb-0 table-borderless">
                        <tbody>
                          {checkOrderDetails.item.vat_system === "igst" ? (
                            <tr>
                              <th className="py-0 pb-1 fk-print-text print-text">
                                <span className="d-block print-text text-uppercase">
                                { checkOrderDetails.item.theBranch?.print_tax }
                                 {checkOrderDetails.item && `(${vatPercent(checkOrderDetails.item.order_bill,checkOrderDetails.item.vat)}%)`}
                                </span>
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(checkOrderDetails.item.vat)}
                              </td>
                            </tr>
                          ) : (
                            <>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text">CGST</span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    parseFloat(checkOrderDetails.item.cgst)
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text">SGST</span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    parseFloat(checkOrderDetails.item.sgst)
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    )}
                    {getSystemSettings(generalSettings, "sDiscount") ===
                      "flat" && (
                      <>
                        {parseFloat(checkOrderDetails.item.service_charge) >
                          0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("D.Charge"))}
                                  </span>
                                </th>

                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.service_charge
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}

                        {checkOrderDetails.item.service_amount &&
                          parseFloat(checkOrderDetails.item.service_amount) >
                            0 ? (
                            <table className="table mb-0 table-borderless">
                             <tbody> 
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("S.Charge"))}
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {checkOrderDetails.item.service_amount
                                    ? formatPrice(
                                        parseFloat(
                                          checkOrderDetails.item.service_amount
                                        )
                                      )
                                    : 0}
                                </td>
                              </tr>
                             </tbody>
                            </table>
                          ) : null}

                        {(parseFloat(checkOrderDetails.item.discount) > 0 || parseFloat(checkOrderDetails.item.discount_amount) > 0) && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("discount"))}
                                    {checkOrderDetails && checkOrderDetails.item.scheme_id !== null && parseFloat(checkOrderDetails.item.discount) > 0 && `(${parseFloat(checkOrderDetails.item.discount)}%)`}
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(checkOrderDetails.item.discount_amount)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </>
                    )}
                    
                        {/*parseFloat(checkOrderDetails.item.discount) > 0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("discount"))}
                                    {checkOrderDetails.item &&
                                      "(" +
                                        checkOrderDetails.item.discount +
                                        "%)"}
                                  </span>
                                </th>
                                <td className="fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.discount_amount
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                            )*/}

                    {getSystemSettings(generalSettings, "sDiscount") ===
                      "percentage" && (
                      <>
                        {parseFloat(checkOrderDetails.item.service_charge) >
                          0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("D.Charge"))}
                                    {checkOrderDetails.item &&
                                      "(" +
                                        checkOrderDetails.item.service_charge +
                                        "%)"}
                                  </span>
                                </th>

                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.service_charge /
                                        100)
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}

                        {checkOrderDetails.item.service_amount &&
                          parseFloat(checkOrderDetails.item.service_amount) >
                            0 ? (
                            <table className="table mb-0 table-borderless">
                             <tbody> 
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("S.Charge"))}
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {checkOrderDetails.item.service_amount
                                    ? formatPrice(
                                        parseFloat(
                                          checkOrderDetails.item.service_amount
                                        )
                                      )
                                    : 0}
                                </td>
                              </tr>
                             </tbody>
                            </table>
                          ) : null}

                        {parseFloat(checkOrderDetails.item.discount) > 0 && (
                          <table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                  <span className="d-block">
                                    {_t(t("discount"))}
                                    {checkOrderDetails.item && parseFloat(checkOrderDetails.item.discount) > 0 &&
                                      "(" +
                                        checkOrderDetails.item.discount +
                                        "%)"}
                                  </span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(
                                    checkOrderDetails.item.order_bill *
                                      (checkOrderDetails.item.discount / 100)
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </>
                    )}

                    <div className="myBorder mb-1"></div>
                    <table className="table mb-0 table-borderless">
                      <tbody>
                        {(checkOrderDetails.item.theBranch.fbr_integration ===
                          1 || checkOrderDetails.item.theBranch.pbr_integration === 1 || checkOrderDetails.item.theBranch?.sbr_integration === 1) &&
                          checkOrderDetails.item.is_settled && (checkOrderDetails.item.fbr_pos_invoice_no || checkOrderDetails.item.srb_pos_invoice_no) && (
                            <tr>
                              <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                <span className="d-block">
                                  {_t(t("POS Invoice Fee"))}
                                </span>
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {_t(t("1.00"))}
                              </td>
                            </tr>
                          )}
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            <span className="d-block">
                              {_t(t("grand total"))}
                            </span>
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {(checkOrderDetails.item.theBranch
                              .fbr_integration === 1 || checkOrderDetails.item.theBranch.pbr_integration === 1 || checkOrderDetails.item.theBranch?.sbr_integration === 1) &&
                            checkOrderDetails.item.is_settled && (checkOrderDetails.item.fbr_pos_invoice_no || checkOrderDetails.item.srb_pos_invoice_no)
                              ? formatPrice(
                                  parseFloat(
                                    checkOrderDetails.item.total_payable
                                  ) + 1
                                )
                              : formatPrice(
                                  checkOrderDetails.item.total_payable
                                )}
                          </td>
                        </tr>
                        <tr>
                          {checkOrderDetails.item.is_refund === 1 ? (
                            <>
                              <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                <span className="d-block">
                                  {_t(t("Refund Amount"))}
                                </span>
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                  checkOrderDetails.item.paid_amount
                                )}
                              </td>
                            </>
                          ) : 
                          checkOrderDetails.item.is_foc === 1 ?
                          (
                            <>
                            </>
                          ):
                          (
                            <>
                              <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                <span className="d-block">
                                  {_t(t("Paid Amount"))}
                                </span>
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                  checkOrderDetails.item.paid_amount
                                )}
                              </td>
                            </>
                          )}
                        </tr>

                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            <span className="d-block">
                              {_t(t("Return Amount"))}
                            </span>
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {checkOrderDetails.item.paid_amount -
                              checkOrderDetails.item.total_payable >
                            0
                              ? formatPrice(
                                  parseFloat(
                                    checkOrderDetails.item.paid_amount -
                                      checkOrderDetails.item.total_payable
                                  )
                                )
                              : formatPrice(0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {(checkOrderDetails.item.theBranch.fbr_integration === 1 || checkOrderDetails.item.theBranch.pbr_integration === 1) &&
                      checkOrderDetails.item.is_settled &&
                      (checkOrderDetails.item.fbr_pos_invoice_no ||
                        checkOrderDetails.fbr_pos_invoice_no) && (
                          printText = "Verify this invoice through FBR TaxAsaan MobileApp or SMS at 9966",
                          taxTaken = checkOrderDetails.item.theBranch.fbr_integration ? "FBR" : "PBR",
                          taxImage = checkOrderDetails.item.theBranch.fbr_integration ? "/assets/img/pos-invoice-system.png" : "/assets/img/PRA.png",
                          qrPrint(taxTaken,printText,checkOrderDetails.item.fbr_pos_invoice_no,taxImage)  
                        // <div className="mb-1">
                        // <div className="myBorder"></div>
                        //   <p className="print-text fk-print-text text-capitalize text-center">
                        //     FBR Invoice Number{" "}
                        //     {checkOrderDetails.item.fbr_pos_invoice_no}
                        //   </p>
                        //   <div className="mb-1">
                        //     <div className="float-left">
                        //       <img
                        //         src={checkOrderDetails.item.theBranch.fbr_integration ? "/assets/img/pos-invoice-system.png" : ""}
                        //         height="60"
                        //         alt="FBR Invoice"
                        //       />
                        //     </div>
                        //     <div className="float-right">
                        //       {checkOrderDetails.item?.fbr_pos_invoice_no && (
                        //         <QRCode
                        //           value={
                        //             checkOrderDetails.item.fbr_pos_invoice_no
                        //           }
                        //           size="60"
                        //         />
                        //       )}
                        //     </div>
                        //     <div style={{ clear: "both" }}></div>
                        //   </div>
                        //   <p className="print-text fk-print-text text-capitalize text-center">
                        //     Verify this invoice through FBR TaxAsaan MobileApp
                        //     or SMS at 9966 <br></br> and win exciting prizes in
                        //     draw.
                        //   </p>
                        // </div>
                      )}

                    {checkOrderDetails.item.theBranch.sbr_integration === 1 &&
                      checkOrderDetails.item.is_settled &&
                      (checkOrderDetails.item.srb_pos_invoice_no ||
                        checkOrderDetails.srb_pos_invoice_no) && (
                          printText = "Verify this invoice through eSRB MobileApp or SMS at 9697",
                          taxTaken = "SBR",
                          taxImage = "/assets/img/srb_pos_image.png",
                          qrPrint(taxTaken,printText,checkOrderDetails.item.srb_pos_invoice_no,taxImage)   
                        // <div className="mb-1">
                        //   <p className="print-text fk-print-text text-capitalize text-center mb-0">
                        //     SRB Invoice No#
                        //   </p>
                        //   <p className="print-text fk-print-text text-capitalize text-center">
                        //     {checkOrderDetails.srb_pos_invoice_no_for_print}
                        //   </p>
                        //   <div className="mb-1">
                        //     <div className="float-left">
                        //       <img
                        //         src="/assets/img/srb_pos_image.png"
                        //         height="100"
                        //         alt="SRB Invoice"
                        //       />
                        //     </div>
                        //     <div className="float-right">
                        //       <QRCode
                        //         value={
                        //           checkOrderDetails.srb_pos_invoice_no_for_print
                        //         }
                        //         size="100"
                        //       />
                        //     </div>
                        //     <div style={{ clear: "both" }}></div>
                        //   </div>
                        //   <p className="print-text fk-print-text text-capitalize text-center">
                        //     Verify this invoice through SRB MyTax Mobile App or
                        //     SMS at ____ <br></br> and win exciting prizes in
                        //     draw.
                        //   </p>
                        // </div>
                      )}

                    <div className="myBorder"></div>
                    {checkOrderDetails.item?.note_to_rider && checkOrderDetails.item?.note_to_rider !== "" && 
                      <p className="mb-1 print-text fk-print-text text-capitalize mx-1">
                      {"Rider Note : " +
                          (checkOrderDetails.item.note_to_rider && 
                            checkOrderDetails.item.note_to_rider !== "" &&
                            checkOrderDetails.item.note_to_rider
                        )}
                      </p>    
                     }
                    <p style={{ whiteSpace: "pre-line",lineHeight:"0.8rem" }} className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {getSystemSettings(generalSettings, "type_print_footer")}
                    </p>
                    <p className="mb-0 sm-text fk-print-text text-capitalize text-center">
                      {_t(t("bill prepared by"))}:{" "}
                      {checkOrderDetails.item &&
                        checkOrderDetails.item.user_name}
                    </p>
                    <p className="mb-0 sm-text fk-print-text text-capitalize text-center">
                      {_t(t(""))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Settle modal */}
      <div className="modal fade" id="orderDetails" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {/* show order token on modal header */}
                  {_t(t("Order details, Token"))}: #
                  {checkOrderDetails.item && checkOrderDetails.item.token?.id}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* if loading true show loading effect */}
            {loading ? (
              <div className="modal-body">{modalLoading(5)}</div>
            ) : (
              <div className="modal-body">
                {checkOrderDetails.item &&
                parseInt(checkOrderDetails.item.is_settled) === 0 ? (
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                        <div className="text-right">
                          {checkOrderDetails.settle &&
                            paidMoney >
                              parseFloat(
                                checkOrderDetails.item.total_payable
                              ) && (
                              <span className="mr-2 text-secondary font-weight-bold">
                                Return: {currencySymbolLeft()}
                                {formatPrice(returnMoneyUsd)}
                                {currencySymbolRight()}{" "}
                              </span>
                            )}
                          {checkOrderDetails.settle ? (
                            <button
                              className="btn btn-primary px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: false,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Cancel"))}
                            </button>
                          ) : (
                            <button
                              className="btn btn-success px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: true,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Settle order"))}
                            </button>
                          )}
                        </div>
                      )}
                  </>
                ) : (
                  // if this item is not settled then show settle-cancel button else, show this notification
                  ""
                )}
                {checkOrderDetails.item &&
                  //show this if order is cancelled
                  parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                    <div className="text-center bg-secondary text-white py-2">
                      {_t(t("This order has been cancelled"))}
                    </div>
                  )}
                {/* show this if order settle is not true, if true show payment input field */}
                {!checkOrderDetails.settle ? (
                  <div class="col-12 filtr-item">
                    <div class="fk-order-token t-bg-white">
                      <div class="fk-order-token__body">
                        <div class="fk-addons-table">
                          <div class="fk-addons-table__head text-center">
                            {_t(t("order token"))}: #
                            {checkOrderDetails.item &&
                              checkOrderDetails.item.token?.id}
                          </div>
                          <div class="fk-addons-table__info">
                            <div class="row g-0">
                              <div class="col-2 text-center border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("S/L"))}
                                </span>
                              </div>
                              <div class="col-3 text-center border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("food"))}
                                </span>
                              </div>
                              <div class="col-4 text-left pl-2 border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("Additional Info"))}
                                </span>
                              </div>
                              <div class="col-2 text-center border-right">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("QTY"))}
                                </span>
                              </div>
                              <div class="col-1 text-center">
                                <span class="fk-addons-table__info-text text-capitalize">
                                  {_t(t("Status"))}
                                </span>
                              </div>
                            </div>
                          </div>
                          {checkOrderDetails.item &&
                            checkOrderDetails.item.orderedItems.map(
                              (thisItem, indexThisItem) => {
                                return (
                                  <div class="fk-addons-table__body-row">
                                    <div class="row g-0">
                                      <div class="col-2 text-center border-right d-flex">
                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                          {indexThisItem + 1}
                                        </span>
                                      </div>
                                      <div class="col-3 text-center border-right d-flex">
                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                          {thisItem.food_item} (
                                          {thisItem.food_group})
                                        </span>
                                      </div>
                                      <div class="col-4 text-center border-right t-pl-10 t-pr-10">
                                        {thisItem.variation !== null && (
                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                            <span class="font-weight-bold mr-1">
                                              {_t(t("variation"))}:
                                            </span>
                                            {thisItem.variation}
                                          </span>
                                        )}

                                        {thisItem.properties !== null && (
                                          <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                            <span class="font-weight-bold mr-1">
                                              {_t(t("properties"))}:
                                            </span>
                                            {JSON.parse(
                                              thisItem.properties
                                            ).map((propertyItem, thisIndex) => {
                                              if (
                                                thisIndex !==
                                                JSON.parse(thisItem.properties)
                                                  .length -
                                                  1
                                              ) {
                                                return (
                                                  propertyItem.property +
                                                  `${
                                                    propertyItem.quantity > 1
                                                      ? "(" +
                                                        propertyItem.quantity +
                                                        ")"
                                                      : ""
                                                  }` +
                                                  ", "
                                                );
                                              } else {
                                                return (
                                                  propertyItem.property +
                                                  `${
                                                    propertyItem.quantity > 1
                                                      ? "(" +
                                                        propertyItem.quantity +
                                                        ")"
                                                      : ""
                                                  }`
                                                );
                                              }
                                            })}
                                          </span>
                                        )}
                                      </div>
                                      <div class="col-2 text-center border-right d-flex">
                                        <span class="fk-addons-table__info-text text-capitalize m-auto">
                                          {thisItem.quantity}
                                        </span>
                                      </div>

                                      <div class="col-1 text-center d-flex">
                                        <label class="mx-checkbox mx-checkbox--empty m-auto">
                                          <span class="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                            {parseInt(thisItem.is_cooking) ===
                                            1 ? (
                                              [
                                                parseInt(thisItem.is_ready) ===
                                                1 ? (
                                                  <i
                                                    className="fa fa-check text-success"
                                                    title={_t(t("Ready"))}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-cutlery text-secondary"
                                                    title={_t(t("Cooking"))}
                                                  ></i>
                                                ),
                                              ]
                                            ) : (
                                              <i
                                                className="fa fa-times text-primary"
                                                title={_t(t("Pending"))}
                                              ></i>
                                            )}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Order details"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Received by"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.user_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Customer"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.customer_name}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">{_t(t("Contact"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_phn_no}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Delivery Address"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_address}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Branch"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.branch_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Department"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.dept_tag_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Table"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.table_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Waiter"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.waiter_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                  
                    {checkOrderDetails.item &&
                    checkOrderDetails.item.vat_system === "igst" ? (
                      <tr>
                        <td className="text-capitalized">{_t(t("SST"))}</td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.vat)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td className="text-capitalized">{_t(t("CGST"))}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  parseFloat(checkOrderDetails.item.cgst)
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-capitalized">{_t(t("SGST"))}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  parseFloat(checkOrderDetails.item.sgst)
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    )}

                    {/* sdiscount */}
                    {getSystemSettings(generalSettings, "sDiscount") ===
                      "flat" && (
                      <>
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Service charge"))}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  checkOrderDetails.item.service_charge
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Discount"))}
                          </td>
                          <td>
                          {checkOrderDetails.item && (
                            parseFloat(checkOrderDetails.item.discount_amount) > 0 ?
                            <>
                            {currencySymbolLeft()}
                            {formatPrice(parseFloat(checkOrderDetails.item.discount_amount))}
                            {currencySymbolRight()}
                            </>
                            :
                            <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.discount)}
                            {currencySymbolRight()}
                            </>
                         
                        )}
                          </td>
                        </tr>
                      </>
                    )}

                    {getSystemSettings(generalSettings, "sDiscount") ===
                      "percentage" && (
                      <>
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Service charge"))}
                            {checkOrderDetails.item &&
                              "(" +
                                checkOrderDetails.item.service_charge +
                                "%)"}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  checkOrderDetails.item.order_bill *
                                    (checkOrderDetails.item.service_charge /
                                      100)
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Discount"))}
                            {checkOrderDetails.item &&
                              "(" + checkOrderDetails.item.discount + "%)"}
                          </td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  checkOrderDetails.item.order_bill *
                                    (checkOrderDetails.item.discount / 100)
                                )}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    {/* sDiscount */}
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Department Commission"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(
                              checkOrderDetails.item.dept_commission
                            )}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Total bill"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Paid amount"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.paid_amount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    {checkOrderDetails.item &&
                    parseFloat(
                      checkOrderDetails.item.total_payable -
                        checkOrderDetails.item.paid_amount
                    ) >= 0 ? (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Due amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.total_payable -
                                    checkOrderDetails.item.paid_amount
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Return amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.paid_amount -
                                    checkOrderDetails.item.total_payable
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Settle modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {checkOrderDetails.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    {/*<div className="col-5 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedOrder.searched
                              ? _t(t("Settled orders"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                       </div>
                      <div className="col-5 t-mb-15">
                      <span className="px-4 t-link fk-breadcrumb__link">
                        Filter By
                      </span>
                    </div>*/}
                    <div className="col-md-4 col-lg-4 t-mb-15 mb-md-0"
                    style={{ borderRight: "1px solid #6c757da6" }}
                    >
                      <ul className="t-list fk-sort align-items-center mr-3">
                         <li className="fk-breadcrumb__list mr-3">
                         <span className="t-link fk-breadcrumb__link text-capitalize">
                         {!searchedOrder.searched
                           ? _t(t("Settled orders"))
                           : _t(t("Search Result"))}
                         </span>
                         </li>
                        <div className="input-group col">
                          <div className="form-file">
                            <input
                              type="text"
                              className="form-control form-control--light-1 rounded-left"
                              placeholder={
                                _t(t("Search by token, customer, branch, order type")) +
                                ".."
                              }
                              onChange={handleSearch}
                            />
                          </div>
                          <button className="btn btn-primary rounded-right" type="button">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6">
                    <div className="d-flex px-4 align-items-center gx-2">
                      {
                        availableDeliveryTypes && availableDeliveryTypes?.map((orderType)=>{
                          return(
                            <div className="mr-3">
                            <input
                            className={`submit-filter ${activebtn === orderType.name ? "active-btn" : ""} `}
                            id = {orderType.name}
                            name = {orderType.name}
                            value= {orderType.name}
                            type = "submit"
                            onClick={(e) => {
                              setActivebtn(e.target.name);
                              handleSearch(e)
                            }}
                            />
                          </div>
                          )
                        })
                      }
                      {/*<div className="mr-3">
                        <input
                        className={`submit-filter ${activebtn === "Dine-in" ? "active-btn" : ""} `}
                        id="Dine-in"
                        name="Dine-in"
                        value="Dine-In"
                        type="submit"
                        onClick={(e) => {
                          setActivebtn(e.target.name);
                          handleSearch(e)
                        }}
                        />
                      </div>
                      <div className="mr-3">
                        <input
                         className={`submit-filter ${activebtn === "delivery" ? "active-btn" : ""} `}
                         id="delivery"
                         name="delivery"
                         value="Delivery"
                         type="submit"
                         onClick={(e) =>{ 
                          setActivebtn(e.target.name)
                          handleSearch(e)
                         }}
                        />
                      </div>
                      <div className="mr-3">
                        <input
                         className={`submit-filter ${activebtn === "takeaway" ? "active-btn" : ""} `}
                         id="takeaway"
                         name="takeaway"
                         value="Takeaway"
                         type="submit"
                         onClick={(e) =>{
                          setActivebtn(e.target.name)
                          handleSearch(e)
                         }}
                        />
                        </div>*/}
                      <div className="mr-3">
                        <input
                         className={`submit-filter ${activebtn === "all" ? "active-btn" : ""} `}
                         id="all"
                         name="all"
                         value="All"
                         type="submit"
                         onClick={(e) =>{ 
                          setActivebtn(e.target.name)
                          handleSearch(e)
                         }}
                        />
                      </div>
                      
                      <div className="mr-3">
                        <button onClick={()=> handleSrbConfirmation()} className="btn btn-sm btn-danger rounded" title="Sync if SRB/PRA server is unresponsive and integration is off">
                          Sync Now
                        </button>
                      </div>
                    </div>
                  </div>
                    <div className="col-md-2 col-lg-2">
                      <div className="row align-items-center justify-content-end gx-2">
                        <div className="col-6">
                          <NavLink
                            to="/dashboard/pos"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100 rounded"
                          >
                            {_t(t("POS"))}
                          </NavLink>
                        </div>
                        <div className="col-6">
                          <NavLink
                            to="/dashboard/pos/submitted"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100 rounded"
                          >
                            {_t(t("Submitted"))}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-settle" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Token"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Time"))}
                              </th>
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Order Type"))}
                            </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Table"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Ref No"))}
                              </th>
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("User Name"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {console.log("setl ", settledOrders)}
                            {!searchedOrder.searched
                              ? [
                                  settledOrders && [
                                    settledOrders.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      settledOrders.data.map((item, index) => {
                                        return itemListContent(item,index)
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  searchedOrder && [
                                    searchedOrder.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedOrder.list.map((item, index) => {
                                        return itemListContent(item,index)
                                      })
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* pagination loading effect */}
              {checkOrderDetails.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedOrder.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                settledOrders,
                                setPaginatedSettledOrders
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(settledOrders)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedOrder({
                                      ...searchedOrder,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedOrder,
                                    settledOrdersForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Settled;
